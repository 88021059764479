import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga4';
import Header from '../../components/Header';
import {
  Container,
  Steps,
  ProductName,
  SignInButton,
  AuthButtons,
  AuxTextFooter,
  ContainerText,
  StoreBadges,
  BadgeButtons,
  EmbedQRCode,
  CopyButton,
} from './styles';
import { IProps } from './contracts';
import { IStore } from '../../contracts';
import api, { api2 } from '../../services/api';
import MetUsAt from '../../components/MetUsAt';
import ReferralIndicationInfoModal from '../../components/ReferralIndicationInfoModal';

const otherOptions = [2, 3];

function PaymentSuccess(props: IProps) {
  const userName = useSelector((state: IStore) => state.auth.profile.name);
  const id = useSelector((state: IStore) => state.auth.profile.id);
  const paymentToken = useSelector((state: IStore) => state.auth.paymentToken);
  const token = useSelector((state: IStore) => state.auth.token);
  const [copyButtonText, setCopyButtonText] = useState('Copiar código Pix');
  const [requireMetUsAt, setRequireMetUsAt] = useState(false);
  const [indicationModal, setIndicationModal] = useState(false);

  useEffect(() => {
    if (ReactGA.isInitialized) {
      ReactGA.set({ userId: id });
      ReactGA.gtag('event', 'purchase', {
        'User-ID': id,
        userId: id,
        affiliation: '',
        coupon: props.location.state.couponCode,
        currency: 'BRL',
        items: [
          {
            item_id: props.location.state.productId,
            item_name: props.location.state.productName,
            coupon: props.location.state.couponCode,
            discount: props.location.state.discount,
            affiliation: '',
            item_brand: '',
            item_category: '',
            item_variant: '',
            price: props.location.state.productValue,
            currency: 'BRL',
            quantity: 1,
          },
        ],
        transaction_id: props.location.state.billId,
        shipping: 0,
        value: props.location.state.productValue,
        tax: 0,
      });
    }
  }, []);

  useEffect(() => {
    api.put(`/payment_tokens/${paymentToken}`, { is_valid: 0 });
  }, []);

  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopyButtonText('Código copiado!');
  };

  useEffect(() => {
    api2.get('/user_metadata')
      .then((response) => {
        if (!response.data.met_us_at) {
          setRequireMetUsAt(true);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message === 'user_metadata_not_found') {
          setRequireMetUsAt(true);
        }
      });
  }, []);

  useEffect(() => {
    if (['ESSENTIAL', 'PERSONAL', 'START'].some((t) => t === props.location.state?.subscriptionType)) {
      setTimeout(() => {
        setIndicationModal(true);
      }, 1000);
    }
  }, []);

  return (
    <>
      <ReferralIndicationInfoModal
        showModal={indicationModal}
        setShowModal={setIndicationModal}
      />

      <Header />

      <Steps>
        <p>Confirmação</p>
      </Steps>

      {
        requireMetUsAt
          ? <MetUsAt action={() => setRequireMetUsAt(false)} />
          : (
            <>
              <Container>
                <strong>
                  <ProductName>{props.location.state.productName}</ProductName>
                </strong>

                <strong>
                  <ContainerText>
                    Desejamos muito boas vindas,
                    {' '}
                    {userName}
                    !
                  </ContainerText>
                </strong>

                {
                  (
                    props.location.state.paymentOption === 1
                    || (
                      otherOptions.includes(props.location.state.paymentOption)
                      && !props.location.state.billLink
                    )
                  )
                  && (
                    <>
                      <ContainerText>Seu pagamento foi confirmado com sucesso. Muito em breve você vai receber uma mensagem de WhatsApp, para direcionarmos o atendimento junto ao seu treinador.</ContainerText>

                      <ContainerText>
                        Em caso de dúvidas ou caso não receba a mensagem e em até 1(um) dia útil, estamos à disposição neste WhatsApp:
                        <a style={{ color: 'white' }} href="https://wa.me/551151987088" target="_blank" rel="noreferrer">(11) 5198-7088.</a>

                      </ContainerText>

                      <ContainerText>O acesso aos conteúdos e demais recursos de nossa plataforma já estão disponíveis em nosso aplicativo ou site.</ContainerText>
                    </>
                  )
                }

                {
                  props.location.state.paymentOption === 2
                  && props.location.state.billLink
                  && (
                    <>
                      <ContainerText>No botão abaixo, acesse a sua fatura para pagamento com o boleto.</ContainerText>

                      <ContainerText>No pagamento com boleto, a confirmação ocorre em até 3 dias úteis.</ContainerText>
                    </>
                  )
                }

                {
                  props.location.state.paymentOption === 3
                  && props.location.state.billLink
                  && (
                    <>
                      <ContainerText>Escaneie o QR Code abaixo ou copie o código e cole no aplicativo do seu banco clicando no botão Copiar código Pix.</ContainerText>

                      <ContainerText>No pagamento com o pix, a confirmação é imediata.</ContainerText>

                      <EmbedQRCode type="image/svg+xml" src={props.location.state.billLink} title="QR Code para pagamento" />
                    </>
                  )
                }
              </Container>

              {
                (
                  props.location.state.paymentOption === 1
                  || (
                    otherOptions.includes(props.location.state.paymentOption)
                    && !props.location.state.billLink
                  )
                )
                && (
                  <>
                    <StoreBadges>
                      <p>Baixe ou acesse nosso aplicativo:</p>

                      <BadgeButtons>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.corridaperfeita.app&hl=pt-BR&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                          style={{
                            display: 'inline-block',
                            overflow: 'hidden',
                            borderRadius: '13px',
                            width: '200px',
                            height: '76px',
                          }}
                        >
                          <img
                            src="https://play.google.com/intl/pt-BR/badges/static/images/badges/pt-br_badge_web_generic.png"
                            alt="Disponível no Google Play"
                            style={{
                              borderRadius: '13px',
                              width: '200px',
                              height: '76px',
                            }}
                          />
                        </a>

                        <a
                          href="https://apps.apple.com/us/app/corrida-perfeita-v2/id1615429884?itsct=apps_box_badge&amp;itscg=30200"
                          style={{
                            display: 'inline-block',
                            overflow: 'hidden',
                            borderRadius: '13px',
                            width: '200px',
                            height: '60px',
                          }}
                        >
                          <img
                            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/pt-br?size=250x83&amp;releaseDate=1650499200&h=3be987139f1c888ed94555bc516389c6"
                            alt="Baixar na App Store"
                            style={{
                              borderRadius: '13px',
                              width: '200px',
                              height: '60px',
                            }}
                          />
                        </a>
                      </BadgeButtons>
                    </StoreBadges>

                    <AuthButtons>
                      <p>Ou acesse pelo nosso site:</p>
                      <SignInButton href={`${process.env.REACT_APP_APP2_BASE_URL}?token=${token}`} target="_blank">Acessar plataforma</SignInButton>
                    </AuthButtons>

                    <AuxTextFooter>
                      <a href={`${process.env.REACT_APP_APP2_BASE_URL}?token=${token}`} target="_blank" rel="noreferrer">{process.env.REACT_APP_APP2_BASE_URL}</a>
                    </AuxTextFooter>
                  </>
                )
              }

              {
                props.location.state.paymentOption === 2
                && props.location.state.billLink
                && (
                  <AuthButtons>
                    <SignInButton
                      href={props.location.state.billLink}
                      target="_blank"
                    >
                      <strong>Abrir fatura de pagamento</strong>
                    </SignInButton>
                  </AuthButtons>
                )
              }

              {
                props.location.state.paymentOption === 3
                && props.location.state.pixCode
                && (
                  <AuthButtons>
                    <CopyButton
                      onClick={() => handleCopyToClipboard(props.location.state.pixCode)}
                    >
                      <strong>{copyButtonText}</strong>
                    </CopyButton>
                  </AuthButtons>
                )
              }
            </>
          )
      }
    </>
  );
}

export default PaymentSuccess;
